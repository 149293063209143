<template>
  <v-alert
    border="left"
    class="confirmation__terms ma-0"
    color="primary"
    text
  >
    <v-row>
      <v-col class="pt-0">
        <h3 class="text-subtitle-1 font-weight-bold mt-4 mb-1">
          <translate>
            Read and accept the terms and conditions of the loan agreement
          </translate>
        </h3>
        <p class="text-caption mb-4 grey--text text--darken-2">
          <translate>
            By accepting, you confirm and sign the loan agreement.
          </translate>
        </p>
        <div
          v-for="(term, i) in terms"
          :key="term.name"
          :class="`confirmation__${term.name}--wrapper`"
        >
          <cw-checkbox
            v-if="term.required"
            :id="term.name"
            :class="{ 'mb-4': i < (terms.length - 1) }"
            :value="term.value"
            class="mt-4"
            rules="required"
            prevent-set-data
            hide-details
            @input="val => onTermChange({ i, val })"
          >
            <template #label>
              <span class="mr-1" data-test>
                <translate>
                  I Agree
                </translate>
              </span>
              <a
                :id="`${term.name}--link`"
                :href="termsLink(term.name)"
                class="term__required"
                target="_blank"
                @click.stop="$eventLogger.clickEvent($event)"
              >{{ term.displayName }}</a>
            </template>
          </cw-checkbox>
          <div v-else class="mt-4">
            <a
              :id="`${term.name}--link`"
              :href="termsLink(term.name)"
              target="_blank"
              @click.stop="$eventLogger.clickEvent($event)"
            >{{ term.displayName }}</a>
          </div>
        </div>
        <cw-form-loader v-if="$wait.waiting('LIST_TERMS')"/>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import * as types from '@shared/store/mutation-types';

export default {
  name: 'CwConfirmationTerms',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
  },

  computed: {
    ...mapState({
      terms: state => state.application.terms,
    }),
  },

  methods: {
    ...mapMutations('application', {
      updateTermValue: types.UPDATE__TERM_VALUE,
    }),

    termsLink(termName) {
      const { hashId } = this.$store.state.route.params;
      return `${process.env.VUE_APP_API_BASE_URL}/appTerms/viewTermsPdf/${hashId}/${termName}`;
    },

    onTermChange(params) {
      this.updateTermValue(params);
    },
  },
};
</script>
